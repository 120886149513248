import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AddBusinessApisAction, AddBusinessSuccessProps } from "../actions";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { LoadingActions, LoadingState } from "../../../store";
import { Store } from "@ngrx/store";
import { BusinessesApiService } from "../../../my-business/services/businesses-api.service";

@Injectable()
export class AddBusinessEffects {
  addBusiness$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddBusinessApisAction.addBusiness),
      switchMap((payload) => {
        const formData = new FormData();
        Array.from(payload.images || []).forEach((item) => formData.append(`images`, item as Blob));

        formData.append("name", payload?.name);
        formData.append("description", payload?.description as string);

        if (payload?.id) {
          return this.businessesApi.updateBusiness(formData, payload?.id).pipe(
            tap(() => this.store.dispatch(LoadingActions.stopLoading())),
            map((response) =>
              AddBusinessApisAction.addBusinessSuccess(response as AddBusinessSuccessProps)
            ),
            catchError((error) => {
              this.store.dispatch(LoadingActions.stopLoading());
              return of(AddBusinessApisAction.addBusinessFailed({ error }));
            })
          );
        } else {
          return this.businessesApi.addBusiness(formData).pipe(
            tap(() => this.store.dispatch(LoadingActions.stopLoading())),
            map((response) =>
              AddBusinessApisAction.addBusinessSuccess(response as AddBusinessSuccessProps)
            ),
            catchError((error) => {
              this.store.dispatch(LoadingActions.stopLoading());
              return of(AddBusinessApisAction.addBusinessFailed({ error }));
            })
          );
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private businessesApi: BusinessesApiService,
    private store: Store<LoadingState>
  ) {}
}
