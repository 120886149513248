import {
  AddBusinessActionProps,
  AddBusinessBasicDetailsProps,
  AddBusinessDetailsImagesProps,
  AddBusinessFailedProps,
  AddBusinessSuccessProps,
} from "../actions";
import { AddBusinessState } from "../add-business.states";

const addBusinessBasicDetail = (
  state: AddBusinessState,
  response: AddBusinessBasicDetailsProps
) => ({
  ...state,
  ...response,
});

const addBusinessBasicImages = (
  state: AddBusinessState,
  response: AddBusinessDetailsImagesProps
) => ({
  ...state,
  images: response?.images || [],
});

const addBusiness = (state: AddBusinessState, response: AddBusinessActionProps) => ({
  ...state,
  name: response?.name || "",
  description: response?.description || "",
  images: response?.images || [],
  id: response?.id || "",
  success: false,
  error: null,
});

const addBusinessSuccess = (state: AddBusinessState, response: AddBusinessSuccessProps) => ({
  ...state,
  name: response?.name || "",
  description: response?.description || "",
  images: response?.images || [],
  id: response?.id || "",
  success: true,
  error: null,
});

const addBusinessFailed = (state: AddBusinessState, error: AddBusinessFailedProps) => ({
  ...state,
  id: "",
  success: false,
  error: error?.error,
});

export default {
  addBusinessBasicDetail,
  addBusinessBasicImages,
  addBusiness,
  addBusinessSuccess,
  addBusinessFailed,
};
