export interface RefreshTokenState {
  accessToken: string;
  refreshToken: string;
  error: unknown;
}

export const refreshTokenInitialState = {
  accessToken: "",
  refreshToken: "",
  error: null,
} as RefreshTokenState;
