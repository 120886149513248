import { createSelector } from "@ngrx/store";
import { addBusinessFeature } from "./add-business.slice";

export const {
  selectName,
  selectDescription,
  selectImages,
  selectAddBusinessState,
  selectError,
  selectBusinessId,
  selectId,
  selectSuccess,
} = addBusinessFeature;

export const selectAddBusinessData = createSelector(
  selectName,
  selectDescription,
  selectImages,
  selectId,
  (name, description, images, id) => {
    return {
      name,
      description,
      images,
      id,
    };
  }
);
