import { Business } from "@menufy/models";

export interface AddBusinessState extends Pick<Business, "id" | "name" | "handle"> {
  images: File[];
  description: string;
  success: boolean;
  error: unknown;
}

export const addBusinessInitialState: AddBusinessState = {
  name: "",
  description: "",
  handle: "",
  images: [],
  id: "",
  success: false,
  error: null,
};
