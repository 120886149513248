import { createFeature, createReducer, on } from "@ngrx/store";
import { refreshTokenInitialState } from "./refresh-token.state";
import { RefreshTokenApisActions } from "./actions";
import refreshTokenReducers from "./reducers/refresh-token.reducers";

const REFRESH_TOKEN_FEATURE_NAME = "refreshToken";

export const refreshTokenFeature = createFeature({
  name: REFRESH_TOKEN_FEATURE_NAME,
  reducer: createReducer(
    refreshTokenInitialState,
    on(RefreshTokenApisActions.refreshTokenSuccess, refreshTokenReducers.refreshTokenSuccess),
    on(RefreshTokenApisActions.refreshTokenFailure, refreshTokenReducers.refreshTokenFailed)
  ),
});

export const refreshTokenSlice = {
  name: refreshTokenFeature.name,
  reducer: refreshTokenFeature?.reducer,
} as const;
