import { createActionGroup, props } from "@ngrx/store";
import { Business } from "@menufy/models";

export interface AddBusinessBasicDetailsProps extends Pick<Business, "name" | "description"> {
  id?: string;
}

export type AddBusinessDetailsImagesProps = { images: File[] };

export type AddBusinessFailedProps = { error: unknown };

export interface AddBusinessActionProps extends AddBusinessBasicDetailsProps {
  images?: File[];
}

export interface AddBusinessSuccessProps extends Omit<Business, "images"> {
  images: File[];
}

export const AddBusinessApisAction = createActionGroup({
  source: "AddBusiness/Apis",
  events: {
    addBusinessBasicDetail: props<AddBusinessBasicDetailsProps>(),
    addBusinessBasicImages: props<AddBusinessDetailsImagesProps>(),
    addBusiness: props<AddBusinessActionProps>(),
    addBusinessSuccess: props<AddBusinessSuccessProps>(),
    addBusinessFailed: props<AddBusinessFailedProps>(),
  },
});
