import { createActionGroup, emptyProps, props } from "@ngrx/store";

export type GetRefreshTokenSuccessProps = { accessToken: string; refreshToken: string };
export type GetRefreshTokenFailedProps = { error: unknown };

export const RefreshTokenApisActions = createActionGroup({
  source: "RefreshToken/Apis",
  events: {
    refreshToken: emptyProps(),
    refreshTokenSuccess: props<GetRefreshTokenSuccessProps>(),
    refreshTokenFailure: props<GetRefreshTokenFailedProps>(),
  },
});
