import { Injectable } from "@angular/core";
import { UserAuthService } from "../../../auth/services/user-auth.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { RefreshTokenApisActions } from "../actions";
import { catchError, map, of, switchMap } from "rxjs";
import { Store } from "@ngrx/store";
import {
  AuthApisActions,
  AuthStateResponse,
  GetLoginSuccessPropsType,
  selectAuthState,
} from "../../../auth/state";

@Injectable()
export class RefreshTokenEffects {
  userData = this.store.selectSignal(selectAuthState)() as GetLoginSuccessPropsType;

  refreshToken$ = createEffect(() =>
    this.action$.pipe(
      ofType(RefreshTokenApisActions.refreshToken),
      switchMap(() => {
        return this.userAuthService.refreshToken().pipe(
          map((state) => {
            this.store.dispatch(
              AuthApisActions.updateUser({
                ...this.userData,
                user: {
                  ...this.userData?.user,
                  accessToken: state.accessToken,
                  refreshToken: state.refreshToken,
                },
              })
            );
            return RefreshTokenApisActions.refreshTokenSuccess(state);
          }),
          catchError((error) => of(RefreshTokenApisActions.refreshTokenFailure(error)))
        );
      })
    )
  );

  constructor(
    private action$: Actions,
    private userAuthService: UserAuthService,
    private store: Store<AuthStateResponse>
  ) {}
}
