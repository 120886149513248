import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { addBusinessInitialState } from "./add-business.states";
import { AddBusinessApisAction } from "./actions";
import addBusinessReducers from "./reducers/add-business.reducers";

const ADD_BUSINESS_FEATURE_NAME = "addBusiness";

export const addBusinessFeature = createFeature({
  name: ADD_BUSINESS_FEATURE_NAME,
  reducer: createReducer(
    addBusinessInitialState,
    on(AddBusinessApisAction.addBusinessBasicDetail, addBusinessReducers.addBusinessBasicDetail),
    on(AddBusinessApisAction.addBusinessBasicImages, addBusinessReducers.addBusinessBasicImages),
    on(AddBusinessApisAction.addBusiness, addBusinessReducers.addBusiness),
    on(AddBusinessApisAction.addBusinessSuccess, addBusinessReducers.addBusinessSuccess),
    on(AddBusinessApisAction.addBusinessFailed, addBusinessReducers.addBusinessFailed)
  ),
  extraSelectors: ({ selectId }) => ({
    selectBusinessId: createSelector(selectId, (id) => id),
  }),
});

export const addBusinessSlice = {
  name: addBusinessFeature.name,
  reducer: addBusinessFeature.reducer,
} as const;
