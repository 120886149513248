import { GetRefreshTokenFailedProps, GetRefreshTokenSuccessProps } from "../actions";
import { RefreshTokenState } from "../refresh-token.state";

const refreshTokenSuccess = (state: RefreshTokenState, response: GetRefreshTokenSuccessProps) => ({
  ...state,
  accessToken: response.accessToken,
  refreshToken: response.refreshToken,
  error: null,
});

const refreshTokenFailed = (state: RefreshTokenState, err: GetRefreshTokenFailedProps) => ({
  ...state,
  accessToken: "",
  refreshToken: "",
  error: err?.error,
});

export default {
  refreshTokenSuccess,
  refreshTokenFailed,
};
